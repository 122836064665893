import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const textfieldDefaultVariants = {
  "variant": "outline",
  "roundness": "medium",
  "size": "md"
}
const textfieldCompoundVariants = [
  {
    "size": "sm",
    "roundness": "none",
    "css": {
      "container": {
        "borderRadius": "0"
      }
    }
  },
  {
    "size": "sm",
    "roundness": "medium",
    "css": {
      "container": {
        "borderRadius": "token(radii.control.sm)"
      }
    }
  },
  {
    "size": "sm",
    "roundness": "full",
    "css": {
      "container": {
        "borderRadius": "full"
      }
    }
  },
  {
    "size": "md",
    "roundness": "none",
    "css": {
      "container": {
        "borderRadius": "0"
      }
    }
  },
  {
    "size": "md",
    "roundness": "medium",
    "css": {
      "container": {
        "borderRadius": "token(radii.control.md)"
      }
    }
  },
  {
    "size": "md",
    "roundness": "full",
    "css": {
      "container": {
        "borderRadius": "full"
      }
    }
  }
]

const textfieldSlotNames = [
  [
    "container",
    "textfield__container"
  ],
  [
    "input",
    "textfield__input"
  ],
  [
    "startSlot",
    "textfield__startSlot"
  ],
  [
    "endSlot",
    "textfield__endSlot"
  ]
]
const textfieldSlotFns = /* @__PURE__ */ textfieldSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, textfieldDefaultVariants, getSlotCompoundVariant(textfieldCompoundVariants, slotName))])

const textfieldFn = memo((props = {}) => {
  return Object.fromEntries(textfieldSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const textfieldVariantKeys = [
  "variant",
  "roundness",
  "size"
]
const getVariantProps = (variants) => ({ ...textfieldDefaultVariants, ...compact(variants) })

export const textfield = /* @__PURE__ */ Object.assign(textfieldFn, {
  __recipe__: false,
  __name__: 'textfield',
  raw: (props) => props,
  variantKeys: textfieldVariantKeys,
  variantMap: {
  "variant": [
    "outline",
    "soft",
    "headless"
  ],
  "roundness": [
    "none",
    "medium",
    "full"
  ],
  "size": [
    "sm",
    "md",
    "lg"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, textfieldVariantKeys)
  },
  getVariantProps
})