import { forwardRef } from "react";
import { type TextfieldVariantProps, textfield } from "~/design/generated/recipes";
import { ButtonSlot, type ButtonSlotProps } from "./ButtonSlot";

export type TextFieldProps = TextfieldVariantProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    startSlot?: ButtonSlotProps["slot"];
    endSlot?: ButtonSlotProps["slot"];
  };

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>((props, ref) => {
  const { startSlot, endSlot, variant, placeholder, roundness, size, ...restProps } = props;

  const classes = textfield({
    variant,
    roundness,
    size,
  });

  return (
    <div className={classes.container}>
      {Boolean(startSlot) && (
        <span className={classes.startSlot}>
          <ButtonSlot slot={startSlot} size={size} />
        </span>
      )}
      <input ref={ref} placeholder={placeholder} className={classes.input} {...restProps} />
      {Boolean(endSlot) && (
        <span className={classes.endSlot}>
          <ButtonSlot slot={endSlot} size={size} />
        </span>
      )}
    </div>
  );
});
